import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Tooltip from "@material-ui/core/Tooltip";
import DateIcon from "@material-ui/icons/CalendarToday";
import SpeedIcon from "@material-ui/icons/Speed";
import Alert from "@material-ui/lab/Alert";
import { DateTime } from "luxon";
import React from "react";
import { Link } from "react-router-dom";
import { PingMarkerWrapperProps, PingPopupProps } from "..";
import { UserContext } from "../../../contexts/UserContext";
import { useTranslation } from "../../../hooks/i18n";
import { Account, User } from "../../../interfaces";
import { LocalizedButton } from "../../Button";
import { MediaStatuses } from "../../EventDetail/constants";
import { HyperlapseRequestModal } from "../../Hyperlapse/HyperlapseRequestModal";
import { HyperlapseSubmitModal } from "../../Hyperlapse/HyperlapseSubmitModal";
import { RequestVideoDialog } from "../../Hyperlapse/RequestVideoDialog";
import { LocalizedSpeed } from "../../LocalizedSpeed";
import { LocalizedText } from "../../LocalizedText";
import { PingMarker } from "../../Map";
import { ReactComponent as PingIcon } from "../../Map/assets/arrow-marker.svg";
import { RequestedTooltip } from "../../RequestMediaModal";
import { TooltipPopover } from "../../TooltipPopover";
import { usePingPopupStyles } from "../styles";
import { blockAccessToHyperlapseAndLivestream } from "../../../utils";
import { useAccountsApi } from "../../../hooks/use-accounts";

export const JourneyPingMarker: React.FC<PingMarkerWrapperProps> = React.memo(
  ({
    ping,
    lat,
    lng,
    vehicle,
    onRequestVideo,
    journeyStartTime,
    journeyEndTime,
  }) => {
    const mediaStatus = ping.event?.media[0]?.status;

    const [status, setStatus] = React.useState<MediaStatuses>(
      mediaStatus as MediaStatuses
    );

    return (
      <PingMarker
        lat={lat}
        lng={lng}
        {...ping}
        status={status}
        icon={<PingIcon />}
      >
        <JourneyPingPopup
          {...ping}
          status={status}
          vehicle={vehicle}
          onRequestVideo={onRequestVideo}
          journeyStartTime={journeyStartTime}
          journeyEndTime={journeyEndTime}
          onRequestVideoSuccess={() => {
            setStatus(MediaStatuses.pending);
          }}
        />
      </PingMarker>
    );
  }
);

/**
 * A popover showing the time and speed of a point on a journey, as well as a
 * button to request video from that point
 */
const JourneyPingPopup: React.FC<PingPopupProps> = ({
  time,
  speed,
  event,
  location,
  heading,
  status,
  vehicle,
  journeyStartTime,
  journeyEndTime,
  onRequestVideo,
  onRequestVideoSuccess,
}) => {
  const classes = usePingPopupStyles({});
  const { t } = useTranslation();
  const { getAccount } = useAccountsApi();
  const [data, setData] = React.useState<Account | undefined>(undefined);

  React.useEffect(() => {
    async function fetchData() {
      const data = await getAccount(vehicle.accountId);
      setData(data);
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We only want to run this once
  }, [vehicle.accountId]);

  const provider = vehicle.cameras[0]?.provider;

  const { ctxUser } = React.useContext(UserContext);
  const blockAccountAccess = blockAccessToHyperlapseAndLivestream(data);

  const validateIgnitionOff = (): string => {
    const journeyEndDateTime = DateTime.fromISO(journeyEndTime);
    const overrun = vehicle.status?.cameraOverrun || 0;
    const journeyEndWithOverrun = journeyEndDateTime.plus({ minutes: overrun });
    const currentDateTime = DateTime.now();

    if (currentDateTime.diff(journeyEndWithOverrun).milliseconds > 0) {
      return journeyEndWithOverrun.toFormat("yyyy-MM-dd HH:mm:ss");
    }

    return journeyEndDateTime.toFormat("yyyy-MM-dd HH:mm:ss");
  };

  const [requestedBy, setRequestedBy] = React.useState<User | undefined>(
    event?.media.find((m) => m.requestedBy !== undefined)?.requestedBy
  );
  //Possibly don't need this?
  const [mediaRequesting, setMediaRequesting] = React.useState(false);
  const [mediaRequested, setMediaRequested] = React.useState(
    status === MediaStatuses.pending
  );
  const [mediaAvailable] = React.useState(
    event?.media.some((item) => item.status === MediaStatuses.available)
  );

  //Hyperlapse States
  const [showHyperlapseModal, setShowHyperlapseModal] = React.useState(false);
  const [
    showHyperlapseSuccessModal,
    setShowHyperlapseSuccessModal,
  ] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const popoverOpen = Boolean(anchorEl);

  let popoverContent = null;

  if (mediaRequested && !mediaAvailable) {
    if (requestedBy) {
      popoverContent = (
        <RequestedTooltip
          firstName={requestedBy.firstName}
          lastName={requestedBy.lastName}
          isCurrentUser={ctxUser?.id === requestedBy.id}
        />
      );
    }
  }

  /**
   * Handlers for managing popovers for info about poending media
   * @see https://material-ui.com/components/popover/#mouse-over-interaction
   */
  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleRequestVideo = async () => {
    setMediaRequesting(true);
  };

  const handleVideoRequested = (success: boolean) => {
    setMediaRequesting(false);
    setMediaRequested(success);
    if (success) {
      onRequestVideoSuccess();
      setRequestedBy(ctxUser);
    }
  };

  const hyperlapseTitle = t("request_hyperlapse_tooltip");
  const videoTitle = t("request_video_tooltip");
  return (
    <div className={classes.container} data-testid="journey-map__ping-marker">
      <List dense>
        <ListItem disableGutters>
          <ListItemIcon classes={{ root: classes.icon }}>
            <DateIcon />
          </ListItemIcon>
          <ListItemText>
            {DateTime.fromISO(time).toLocaleString(
              DateTime.TIME_24_WITH_SECONDS
            )}
          </ListItemText>
        </ListItem>
        <ListItem disableGutters>
          <ListItemIcon classes={{ root: classes.icon }}>
            <SpeedIcon />
          </ListItemIcon>
          <ListItemText data-testid="marker_speed">
            <LocalizedSpeed speed={speed} />
            <LocalizedText text="speed_unit" />
          </ListItemText>
        </ListItem>
        {mediaAvailable && (
          <ListItem disableGutters>
            <LocalizedButton
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- The material typings are incorrect here - they don't include the component prop
              // @ts-ignore
              component={Link}
              to={`/events/${vehicle.id}/${event?.id}`}
              fullWidth
            >
              see_video
            </LocalizedButton>
          </ListItem>
        )}
        {mediaRequested && !mediaAvailable && (
          <ListItem disableGutters>
            <Alert
              className={classes.alert}
              severity="info"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            >
              <LocalizedText text="video_requested" />
            </Alert>
            <TooltipPopover
              id="pending_media_popover"
              open={popoverOpen}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
              disableAutoFocus
            >
              {popoverContent}
            </TooltipPopover>
          </ListItem>
        )}
      </List>
      {!mediaRequested && (
        <Tooltip classes={{ tooltip: classes.tooltip }} title={videoTitle}>
          <div>
            <LocalizedButton
              className={classes.button}
              onClick={handleRequestVideo}
              disabled={mediaRequesting}
              fullWidth
            >
              request_video
            </LocalizedButton>
          </div>
        </Tooltip>
      )}

      {["MFL"].includes(provider) && !blockAccountAccess && (
        <Tooltip classes={{ tooltip: classes.tooltip }} title={hyperlapseTitle}>
          <div>
            <LocalizedButton
              className={classes.button}
              onClick={() => setShowHyperlapseModal(true)}
              disabled={showHyperlapseModal}
              fullWidth
            >
              request_hyperlapse
            </LocalizedButton>
          </div>
        </Tooltip>
      )}
      <HyperlapseRequestModal
        start={DateTime.fromISO(time).toFormat("yyyy-MM-dd HH:mm:ss")}
        end={validateIgnitionOff()}
        onHyperlapseRequestSuccess={() => {
          setShowHyperlapseModal(false);
          setShowHyperlapseSuccessModal(true);
        }}
        show={showHyperlapseModal}
        onClose={() => setShowHyperlapseModal(false)}
        vehicleId={vehicle.id}
        startLocation={location}
      />
      <HyperlapseSubmitModal
        open={showHyperlapseSuccessModal}
        onClose={() => setShowHyperlapseSuccessModal(false)}
      />
      <RequestVideoDialog
        show={mediaRequesting}
        onClose={() => setMediaRequesting(false)}
        onRequested={handleVideoRequested}
        heading={heading}
        location={location}
        address={null}
        vehicle={vehicle}
        startDate={DateTime.fromISO(journeyStartTime).toFormat(
          "yyyy-MM-dd HH:mm:ss"
        )}
        endDate={validateIgnitionOff()}
        frameTime={DateTime.fromISO(time).toFormat("yyyy-MM-dd HH:mm:ss")}
        utc={time}
      />
    </div>
  );
};
