import { useCallback, useEffect, useState } from "react";
import { Vehicle } from "../interfaces";
import useLiveApi from "./use-live-api";

interface VehiclesApi {
  data: Vehicle[];
  loading: boolean;
  error: boolean;
  nextPage: () => Promise<void>;
  prevPage: () => Promise<void>;
  hasNextPage: boolean;
  hasPrevPage: boolean;
}

const fetchItems = async (
  apiGatewayFetch: (
    url: string,
    method: string,
    body?: string
  ) => Promise<Response>,
  registration?: string,
  pageSize?: number,
  paginationToken?: string
) => {
  let url = `/vehicles`;
  const query = new URLSearchParams();
  if (pageSize) query.set("limit", String(pageSize));
  if (registration) query.set("registration", registration);
  if (Array.from(query.keys()).length > 0) url += `?${query.toString()}`;
  if (paginationToken) url += `?token=${paginationToken}`;
  const response = await apiGatewayFetch(url, "get");
  if (response.status !== 200) {
    throw new Error(response.status.toString());
  }
  return await response.json();
};

export const useVehiclesForCurrentAccount = (
  registration?: string,
  pageSize?: number
): VehiclesApi => {
  const { apiGatewayFetch } = useLiveApi();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [data, setData] = useState<Vehicle[]>([]);
  const [nextToken, setNextToken] = useState<string | null>(null);
  const [prevTokens, setPrevTokens] = useState<string[]>([]);

  const loadPage = useCallback(
    async (paginationToken?: string) => {
      setLoading(true);
      try {
        const data = await fetchItems(
          apiGatewayFetch,
          registration,
          pageSize,
          paginationToken
        );
        setData(data.items as Vehicle[]);
        setNextToken(data.paginationToken);
        if (paginationToken) {
          setPrevTokens((prev) => [...prev, paginationToken]);
        } else {
          setPrevTokens([]);
        }
      } catch (e) {
        setError(true);
      } finally {
        setLoading(false);
      }
    },
    [apiGatewayFetch, registration, pageSize]
  );

  const nextPage = useCallback(async () => {
    if (nextToken) {
      await loadPage(nextToken);
    }
  }, [nextToken, loadPage]);

  const prevPage = useCallback(async () => {
    const prevToken = prevTokens[prevTokens.length - 1];
    if (prevToken) {
      await loadPage(prevToken);
      setPrevTokens((prev) => prev.slice(0, -1));
    }
  }, [prevTokens, loadPage]);

  const hasNextPage = !!nextToken;
  const hasPrevPage = prevTokens.length > 0;

  useEffect(() => {
    loadPage();

    const interval = setInterval(() => loadPage(), 60000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We only want to run this once
  }, [registration]);

  return { data, nextPage, prevPage, hasNextPage, hasPrevPage, loading, error };
};
