import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Tooltip,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import {
  useStyles,
  StyledTableRow,
  StyledTableCell,
  usePingPopupStyles,
} from "./styles";
import { LocalizedText } from "../LocalizedText/containers";
import { calculateDurationFromDates } from "./transformers";
import {
  CalendarRecordingActionsProps,
  CalendarRecordingsProps,
  RecordingRowProps,
} from "./interfaces";
import { DateTime } from "luxon";
import { LocalizedButton } from "../Button";
import { useTranslation } from "react-i18next";
import { RequestVideoDialog } from "../Hyperlapse/RequestVideoDialog/container";
import { HyperlapseSubmitModal } from "../Hyperlapse/HyperlapseSubmitModal/views";
import { HyperlapseRequestModal } from "../Hyperlapse/HyperlapseRequestModal/containers";

const CalendarRecordingsList: React.FC<CalendarRecordingsProps> = ({
  blockAccountAccess,
  loading,
  selectedVehicleId,
  vehicle,
  recordings,
}) => {
  const classes = useStyles();

  if (!loading && selectedVehicleId === undefined) {
    return (
      <div className={classes.container}>
        <TableContainer className={classes.tableContainer} component={Paper}>
          <div className={classes.emptyMessage}>
            <Alert severity="info">
              <LocalizedText text="no_vehicle_selected" />
            </Alert>
          </div>
        </TableContainer>
      </div>
    );
  }

  if (!loading && recordings?.length === 0) {
    return (
      <div className={classes.container}>
        <TableContainer className={classes.tableContainer} component={Paper}>
          <div className={classes.emptyMessage}>
            <Alert severity="info">
              <LocalizedText text="no_recordings_for_day_selected" />
            </Alert>
          </div>
        </TableContainer>
      </div>
    );
  }

  if (loading) {
    return (
      <div className={classes.container}>
        <TableContainer className={classes.tableContainer} component={Paper}>
          <div data-testid="vehicles-loading" className={classes.tableLoading}>
            Loading
          </div>
        </TableContainer>
      </div>
    );
  }

  const recordingRows =
    vehicle &&
    recordings &&
    recordings?.map((recording) => (
      <RecordingRow
        key={recording.startTime + recording.endTime}
        blockAccountAccess={blockAccountAccess}
        recording={recording}
        vehicle={vehicle}
      />
    ));

  return (
    <div className={classes.container}>
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table
          className={classes.table}
          aria-label="Camera Recordings"
          stickyHeader
        >
          <TableHead>
            <StyledTableRow>
              <StyledTableCell align="center">
                <LocalizedText text="start time" />
              </StyledTableCell>
              <StyledTableCell align="center">
                <LocalizedText text="end time" />
              </StyledTableCell>
              <StyledTableCell align="center">
                <LocalizedText text="recording duration" />
              </StyledTableCell>
              <StyledTableCell align="center" style={{ width: "25%" }}>
                <LocalizedText text="actions" />
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>{recordingRows}</TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default CalendarRecordingsList;

const RecordingRow: React.FC<RecordingRowProps> = ({
  recording,
  blockAccountAccess,
  vehicle,
}) => {
  const duration = calculateDurationFromDates(
    recording.startTime,
    recording.endTime
  );

  return (
    <>
      <StyledTableRow data-testid="recording-row">
        <StyledTableCell component="th" scope="row" align="center">
          {DateTime.fromISO(recording.startTime).toLocaleString(
            DateTime.DATETIME_MED
          )}
        </StyledTableCell>
        <StyledTableCell align="center">
          {DateTime.fromISO(recording.endTime).toLocaleString(
            DateTime.DATETIME_MED
          )}
        </StyledTableCell>
        <StyledTableCell align="center">{duration}</StyledTableCell>
        <StyledTableCell>
          <CalendarRecordingActions
            vehicle={vehicle}
            blockAccountAccess={blockAccountAccess}
            recording={recording}
          />
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
};

const CalendarRecordingActions: React.FC<CalendarRecordingActionsProps> = ({
  vehicle,
  blockAccountAccess,
  recording,
}) => {
  const classes = usePingPopupStyles({});
  const { t } = useTranslation();

  const [showHyperlapseModal, setShowHyperlapseModal] = React.useState(false);
  const [
    showHyperlapseSuccessModal,
    setShowHyperlapseSuccessModal,
  ] = React.useState(false);
  const [isRequesting, setIsRequesting] = React.useState(false);

  const handleRequestVideo = async () => {
    setIsRequesting(true);
  };

  const provider = vehicle.cameras[0]?.provider;
  const hyperlapseTitle = t("request_hyperlapse_tooltip");
  const videoTitle = t("request_video_tooltip");

  return (
    <>
      <Tooltip classes={{ tooltip: classes.tooltip }} title={videoTitle}>
        <LocalizedButton
          className={classes.button}
          onClick={handleRequestVideo}
          disabled={isRequesting}
        >
          request_video
        </LocalizedButton>
      </Tooltip>

      {["MFL"].includes(provider) && !blockAccountAccess && (
        <Tooltip classes={{ tooltip: classes.tooltip }} title={hyperlapseTitle}>
          <LocalizedButton
            className={classes.button}
            onClick={() => setShowHyperlapseModal(true)}
            disabled={showHyperlapseModal}
          >
            request_hyperlapse
          </LocalizedButton>
        </Tooltip>
      )}
      <HyperlapseRequestModal
        start={DateTime.fromISO(recording.startTime).toFormat(
          "yyyy-MM-dd HH:mm:ss"
        )}
        end={DateTime.fromISO(recording.endTime).toFormat(
          "yyyy-MM-dd HH:mm:ss"
        )}
        onHyperlapseRequestSuccess={() => {
          setShowHyperlapseModal(false);
          setShowHyperlapseSuccessModal(true);
        }}
        show={showHyperlapseModal}
        onClose={() => setShowHyperlapseModal(false)}
        vehicleId={vehicle.id}
      />
      <HyperlapseSubmitModal
        open={showHyperlapseSuccessModal}
        onClose={() => setShowHyperlapseSuccessModal(false)}
      />
      <RequestVideoDialog
        show={isRequesting}
        onClose={() => setIsRequesting(false)}
        startDate={DateTime.fromISO(recording.startTime).toFormat(
          "yyyy-MM-dd HH:mm:ss"
        )}
        endDate={DateTime.fromISO(recording.endTime).toFormat(
          "yyyy-MM-dd HH:mm:ss"
        )}
        frameTime={DateTime.fromISO(recording.startTime).toFormat(
          "yyyy-MM-dd HH:mm:ss"
        )}
        utc={recording.startTime}
        vehicle={vehicle}
        location={undefined}
        address={null}
        heading={0}
      />
    </>
  );
};
