import { useEffect, useState } from "react";
import useLiveApi from "./use-live-api";
import { Account, AccountUpdateBody } from "../interfaces";

interface AccountsAsAdminApi {
  getAccounts: () => Promise<Account[] | undefined>;
  deleteAccount: (id: string) => Promise<void>;
  updateAccount: (data: AccountUpdateBody) => Promise<void>;
  createAccount: (data: AccountUpdateBody) => Promise<void>;
  accounts: Account[];
  loading: boolean;
  error: boolean;
  deleting: boolean;
  isUpdating: boolean;
  updateSuccess: boolean;
}

export const useAccountsAsAdminApi = (): AccountsAsAdminApi => {
  const { apiGatewayFetch } = useLiveApi();
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);

  const getAccounts = async () => {
    try {
      setLoading(true);
      const response = await apiGatewayFetch("/accounts", "get");
      if (response.status !== 200) {
        throw new Error(response.status.toString());
      }
      const result = await response.json();
      const accounts = result as Account[];
      setAccounts(accounts);
      return accounts;
    } catch (e) {
      console.error("error fetching accounts", e);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const deleteAccount = async (id: string) => {
    try {
      setLoading(true);
      setDeleting(true);
      const response = await apiGatewayFetch(`/account/${id}`, "delete");
      if (response.status !== 204) {
        throw new Error(response.status.toString());
      }
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
      setDeleting(false);
    }
  };

  const updateAccount = async (data: AccountUpdateBody) => {
    try {
      setIsUpdating(true);
      const response = await apiGatewayFetch(
        `/account/${data.id}`,
        "put",
        JSON.stringify(data)
      );
      if (response.status !== 200) {
        throw new Error(response.status.toString());
      }
      setUpdateSuccess(true);
      getAccounts();
    } catch (e) {
      console.error("error updating account", e);
      setError(true);
      setUpdateSuccess(false);
    } finally {
      setIsUpdating(false);
    }
  };

  const createAccount = async (data: AccountUpdateBody): Promise<void> => {
    const response = await apiGatewayFetch(
      `/account`,
      "post",
      JSON.stringify(data)
    );
    if (response.status !== 201) {
      throw new Error(response.status.toString());
    }
  };

  useEffect(() => {
    const interval = setInterval(() => getAccounts(), 300000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We only want to run this once
  }, []);

  return {
    getAccounts,
    deleteAccount,
    updateAccount,
    createAccount,
    accounts,
    loading,
    error,
    deleting,
    isUpdating,
    updateSuccess,
  };
};
