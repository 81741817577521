import React from "react";
import { useCurrentSsoUser } from "../../hooks/auth";
import { EventContext } from "../EventDetail/EventContext";
import { EventCameraMediaProps } from "./interfaces";
import { EventCameraMediaView } from "./views";

export const EventCameraMedia = (props: EventCameraMediaProps): JSX.Element => {
  const { user } = useCurrentSsoUser();
  const { requestImage, requestVideo } = React.useContext(EventContext);

  return (
    <EventCameraMediaView
      {...props}
      showTools={props.showTools !== undefined ? props.showTools : true}
      onRequestImage={requestImage}
      onRequestVideo={requestVideo}
      currentUserId={user?.id}
    />
  );
};
